import { jsx } from 'react/jsx-runtime';
import React, { useEffect } from 'react';
import { useMediaQuery, ThemeProvider } from '@mui/material';
import '../hooks/index.esm.js';
import { useStateStorage } from '../hooks/useStateStorage.esm.js';

var ThemeSelectorContext = React.createContext(undefined);

function CustomThemeProvider(props) {
  var children = props.children,
      themeMode = props.theme,
      buildTheme = props.builder;
  var prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  var _useStateStorage = useStateStorage({
    key: 'theme-mode',
    defaultValue: 'light',
    shouldStore: false,
    type: 'local'
  }),
      mode = _useStateStorage[0],
      setMode = _useStateStorage[1],
      stored = _useStateStorage[2]; // const [mode, setMode] = React.useState<'light' | 'dark'>('light');


  var contextValue = React.useMemo(function () {
    return {
      mode: mode,
      toggle: function toggle(store) {
        if (store === void 0) {
          store = false;
        }

        // if (store) window.store.local.set('theme_mode', mode === 'light' ? 'dark' : 'light')
        setMode(mode === 'light' ? 'dark' : 'light', store);
      }
    };
  }, [mode, setMode]);
  var theme = React.useMemo(function () {
    return buildTheme(contextValue.mode);
  }, [contextValue.mode, buildTheme]);
  useEffect(function () {
    console.log({
      context: contextValue.mode,
      buildTheme: buildTheme
    });
  }, [contextValue.mode, buildTheme]);
  useEffect(function () {
    if (themeMode) setMode(themeMode);else if (!stored) {
      setMode(prefersDarkMode ? 'dark' : 'light');
    }
  }, [themeMode, prefersDarkMode, stored]);
  return jsx(ThemeSelectorContext.Provider, {
    value: contextValue,
    children: jsx(ThemeProvider, {
      theme: theme,
      children: children
    })
  });
}

export { CustomThemeProvider, ThemeSelectorContext };
