import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {BrowserRouter} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import AppRouter from './routers/app';
import { buildTheme } from './theme';
import { LayoutProvider } from './layout';
import { LoadingFallback, CustomThemeProvider } from '@mtechvault/ams-client-base';
import AuthenticationProvider from "./context/authentication";

type Props = {
    preloader?: HTMLElement | null;
};

function togglePreloader(preloader: HTMLElement) {
    preloader.classList.add('done');
    setTimeout(() => {
        preloader.remove();
    }, 400);
}


function App(props: Props) {
    const { preloader } = props;
    const { t } = useTranslation('common');

    useEffect(() => {
        if (preloader) togglePreloader(preloader);
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Helmet
                title={t('htmlTitle.default')}
                defaultTitle={t('appName')}
                titleTemplate={t('htmlTitle.template')}
            />
            <CustomThemeProvider builder={buildTheme}>
                <CssBaseline />
                <React.Suspense fallback={<LoadingFallback />}>
                <BrowserRouter>
                        <AuthenticationProvider>
                            <LayoutProvider layout={'minimal'}>
                                <SnackbarProvider maxSnack={3}>
                                    <AppRouter />
                                </SnackbarProvider>
                            </LayoutProvider>
                        </AuthenticationProvider>
                </BrowserRouter>
                </React.Suspense>
            </CustomThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
