import { styled, Paper, alpha, Container, Button } from '@mui/material';

var PaperLink = styled(Paper)(function (_ref) {
  var theme = _ref.theme,
      color = _ref.color;
  return {
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'none',
    transition: theme.transitions.create('background'),
    background: alpha(theme.palette[color || 'primary'][theme.palette.mode], .5),
    '&:hover,&:focus': {
      background: alpha(theme.palette[color || 'primary'][theme.palette.mode], .75)
    }
  };
});
var StuckTop = styled('div')(function (_ref2) {
  var _ref3;

  var theme = _ref2.theme;
  return _ref3 = {
    position: 'sticky',
    top: 56,
    background: theme.palette.background.paper,
    zIndex: 100,
    borderBottom: "1px solid " + theme.palette.divider
  }, _ref3[theme.breakpoints.up('sm')] = {
    top: 64
  }, _ref3;
});
var StuckTopPaperHeader = styled(StuckTop)(function (_ref4) {
  var theme = _ref4.theme;
  return {
    boxShadow: theme.shadows[0],
    '&:hover, &:focus': {
      boxShadow: theme.shadows[4]
    }
  };
});
var PageContainer = styled(Container)(function (_ref5) {
  var theme = _ref5.theme;
  return {
    padding: '1rem',
    position: 'relative'
  };
});
var GridButton = styled(Button)(function (_ref6) {
  var theme = _ref6.theme;
  return {
    display: 'flex',
    width: '100%',
    height: '100%',
    textTransform: 'none',
    '& .icon': {
      marginRight: '1rem'
    },
    '& .text': {
      flexGrow: 1
    }
  };
});

export { GridButton, PageContainer, PaperLink, StuckTop, StuckTopPaperHeader };
