import React, {createContext, Dispatch, ReactNode, Reducer, useContext, useMemo, useReducer} from 'react';
import {Struct} from "@mtechvault/ams-types";

type ActionMap = {
    // setAdvertisers: (advertisers: Struct.Advertiser[]) => unknown;
    // setActiveAdvertiser: (advertisers: Struct.Advertiser) => unknown;
    setUser: (user: Struct.User) => unknown;
    setActiveOrganization: (organization: Struct.OrganizationWithOrganizationUser) => unknown;
    setOrganizations: (organizations: Struct.OrganizationWithOrganizationUser[]) => unknown;
    invalidate: () => unknown;
}

type StateActionMap = {
    [s in keyof ActionMap]: (this: State, ...args: Parameters<ActionMap[s]>) => State;
}

type Action<P extends keyof ActionMap> = {
    type: P;
    payload?: Parameters<ActionMap[P]>;
};

type State = {
    // advertisers?: Struct.Advertiser[];
    // activeAdvertiser?: Struct.Advertiser;
    user: Struct.User;
    organizations: Struct.OrganizationWithOrganizationUser[];
    activeOrganization?: Struct.OrganizationWithOrganizationUser;
};

type Context = {
    actions: ActionMap;
    state: State;
};

type Props = {
    defaultState?: State;
    children: ReactNode;
}

export const AuthenticationContext = createContext<Context>({} as never);
AuthenticationContext.displayName = 'AuthenticationContext'

const stateActions: StateActionMap = {
    setUser(user) {

        return {
            ...this,
            user
        };
    },
    setOrganizations(organizations) {
        return {
            ...this,
            organizations,
        }
    },
    setActiveOrganization(activeOrganization) {
        return {
            ...this,
            activeOrganization
        }
    },
    invalidate() {
        return defaultState
    }
}

const defaultState: State = {
    organizations: []
} as never;

const reducer: Reducer<State, Action<keyof ActionMap>> = (state, action) => {
    const {
        type,
        payload = []
    } = action;

    if (!stateActions[type])
        return state;

    return (stateActions[type] as (...args: any[]) => State).apply(state, payload);
};

const actionBuilder: (dispatcher: Dispatch<Action<keyof ActionMap>>) => ActionMap = (dispatcher) => {
    const actions: ActionMap = {} as never;
    Object.keys(stateActions).forEach((key) => {
        if (!(key in stateActions)) {
            return
        }

        actions[key] = (...args) => {
            dispatcher({
                type: key as keyof ActionMap,
                payload: args as Parameters<ActionMap[keyof ActionMap]>
            })
        }
    })
    return actions;
}

function AuthenticationProvider(props: Props) {
    const { children, defaultState: defaultPropState } = props;
    const [ state, dispatch ] = useReducer(reducer, defaultPropState || defaultState);

    const actions = useMemo(() => actionBuilder(dispatch), [dispatch]);
    const contextValue: Context = useMemo(() => ({ state, actions }), [state, actions]);

    return (
        <AuthenticationContext.Provider value={contextValue}>
            {children}
        </AuthenticationContext.Provider>
    )
}

type IncludedProviderProps = React.PropsWithChildren<any>;
type WrappedComponent<P = React.PropsWithChildren<any>> = React.ComponentClass<P> | React.FunctionComponent<P>;
export function withAuthenticationProvider<P extends IncludedProviderProps = IncludedProviderProps>(WrappedComponent: WrappedComponent<P>): React.ComponentClass<P> {
    return class extends React.Component<P> {
        render() {
            return (
                <AuthenticationProvider>
                    <WrappedComponent
                        {...this.props}
                    />
                </AuthenticationProvider>
            )
        }
    };
}

export type AuthenticationContextState = State;
export type AuthenticationContextActions = keyof ActionMap;
export type AuthenticationContextValue = Context;
export type AuthenticationProviderProps = Props;
export default AuthenticationProvider;
