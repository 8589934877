import { objectWithoutPropertiesLoose as _objectWithoutPropertiesLoose, extends as _extends } from '../_virtual/_rollupPluginBabelHelpers.esm.js';
import useSWR from 'swr';
import '../util/index.esm.js';
import { useMemo } from 'react';
import { ApiRequest } from '../util/handleApiRequest.esm.js';

var _excluded = ["canFetch"];

function useApi(request, props) {
  if (props === void 0) {
    props = null;
  }

  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }

  var _ref = props || {},
      _ref$canFetch = _ref.canFetch,
      canFetch = _ref$canFetch === void 0 ? true : _ref$canFetch,
      swrProps = _objectWithoutPropertiesLoose(_ref, _excluded);

  var swr = useMemo(function () {
    return [request].concat(args);
  }, [request].concat(args));

  var _useSWR = useSWR(canFetch ? swr : null, ApiRequest[request], _extends({}, swrProps, {
    use: [function (useSWRNext) {
      return function (key, fetcher, config) {
        // console.log('before')
        // TODO handle hook
        var _swr = useSWRNext(key, fetcher, config); // console.log('after')


        return _swr;
      };
    }]
  })),
      data = _useSWR.data,
      isValidating = _useSWR.isValidating,
      mutate = _useSWR.mutate,
      error = _useSWR.error;

  return _extends({}, data, {
    isValidating: isValidating,
    mutate: mutate,
    error: error || (data == null ? void 0 : data.error)
  });
}

export { useApi };
