import { objectWithoutPropertiesLoose as _objectWithoutPropertiesLoose, extends as _extends } from '../../_virtual/_rollupPluginBabelHelpers.esm.js';
import { jsx } from 'react/jsx-runtime';
import { Tooltip, Typography } from '@mui/material';

var _excluded = ["title", "children", "disabled"];

function Popover(props) {
  var title = props.title,
      children = props.children,
      disabled = props.disabled,
      tooltipProps = _objectWithoutPropertiesLoose(props, _excluded);

  if (disabled || !children) return children;
  return jsx(Tooltip, _extends({
    title: !!title ? title : ''
  }, tooltipProps, {
    children: typeof children === 'string' ? jsx(Typography, {
      children: children
    }) : children
  }));
}

export { Popover };
