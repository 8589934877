import { useRef } from 'react';

var index = 0;
function useUniqueId(namespace) {
  if (namespace === void 0) {
    namespace = 'default';
  }

  // eslint-disable-next-line
  var ref = useRef(namespace + "-" + index++);
  return ref.current;
}

export { useUniqueId };
