import { useApi } from './useApi.esm.js';
import { useState, useMemo, useEffect } from 'react';

function useJWT(isLoggedIn, interval) {
  if (isLoggedIn === void 0) {
    isLoggedIn = false;
  }

  if (interval === void 0) {
    interval = 10000;
  }

  var _useState = useState(typeof interval === 'number' ? interval : 9999999),
      refresh = _useState[0],
      setRefresh = _useState[1];

  var refreshInterval = useMemo(function () {
    return typeof interval === 'number' ? refresh : interval ? 10000 : undefined;
  }, [interval, refresh]);

  var _useApi = useApi('getJWT', {
    refreshInterval: refreshInterval,
    // refreshInterval: process.env.NODE_ENV === 'development' ? 10 * 1000 : 60 * 1000 * 5,
    canFetch: isLoggedIn
  }),
      data = _useApi.data,
      isValidating = _useApi.isValidating,
      mutate = _useApi.mutate,
      error = _useApi.error;

  useEffect(function () {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useJWT', "isLoggedIn=" + isLoggedIn, "refreshInterval=" + refreshInterval, "token=" + (!!(data != null && data.token) ? 'set' : 'undefined'), "expiration=" + (data == null ? void 0 : data.expiration));
    }

    setRefresh(data ? data.expiration - Date.now() - 5000 : 10000);
  }, [data]);
  return {
    token: data == null ? void 0 : data.token,
    expiration: data == null ? void 0 : data.expiration,
    isValidating: isValidating,
    mutate: mutate,
    error: error
  };
}

export { useJWT };
