import { objectWithoutPropertiesLoose as _objectWithoutPropertiesLoose, extends as _extends } from '../../_virtual/_rollupPluginBabelHelpers.esm.js';
import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useMemo, useState } from 'react';
import { styled, Card, CircularProgress, darken, lighten, Chip as Chip$1, Paper, Stack, Box, useMediaQuery, IconButton, CardContent, Typography, Grid, CardActions, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStar$1, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PermissionMap, FULL_DATE_FORMAT } from '@mtechvault/ams-types';
import '../../util/index.esm.js';
import { DateTime } from 'luxon';
import { Popover } from '../Popover/index.esm.js';
import { permissionIcons } from '../../util/permissionIcons.esm.js';

var _excluded = ["t", "access", "group", "size"];
var favoriteStatus = {
  checked: faStar,
  unchecked: faStar$1
};
var accessStatus = {
  checked: faCheck,
  unchecked: faTimes
};
var UndecoratedLink = styled(Link)({
  textDecoration: 'none'
});
var Organization = styled(Card)({
  position: 'relative',
  minWidth: 250,
  marginBottom: '1rem',
  cursor: 'pointer' // '&:hover': {
  //     background: 'green'
  // }

});
var FavoriteContainer = styled('div')({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: '.5rem'
});
var PendingIcon = styled(CircularProgress)({
  position: 'absolute',
  right: '0.8rem',
  top: '0.8rem',
  color: 'rgba(63, 81, 181, 0.25)'
});

var darkOrLight = function darkOrLight(mode) {
  return mode === 'light' ? darken : lighten;
};

var Chip = styled(Chip$1)(function (_ref) {
  var theme = _ref.theme;
  return {
    margin: '.25rem' // background: darkOrLight(theme.palette.mode)(theme.palette.background.paper, 0.1),

  };
});

function AccessIcons(props) {
  var t = props.t,
      access = props.access,
      group = props.group,
      size = props.size,
      stackProps = _objectWithoutPropertiesLoose(props, _excluded);

  if (typeof access === 'undefined' || typeof group === 'undefined') return jsx("span", {});
  var ns = useMemo(function () {
    return access.namespace(group);
  }, [access, group]);
  var nsKeys = useMemo(function () {
    return Array.of.apply(Array, PermissionMap[group].permissions).filter(function (k) {
      return k.indexOf('UNUSED') === -1;
    });
  }, [group]);
  return jsx(Fragment, {
    children: nsKeys.map(function (key) {
      var enabled = ns.hasPermissions(group, [key]);
      var Icon = permissionIcons[key];
      if (!Icon) return null;
      return jsx(Popover, {
        title: t("permissions." + group + "." + key.toLowerCase() + ".title." + (enabled ? 'enabled' : 'disabled')),
        children: jsx(Paper, _extends({
          component: Stack,
          variant: 'outlined',
          p: 1
        }, stackProps, {
          children: jsx(Box, {
            margin: 'auto',
            children: Icon ? jsx(Icon, {
              size: size,
              group: group,
              enabled: enabled
            }) : key + "-" + (enabled ? 'Y' : 'N')
          })
        }))
      }, key);
    })
  });
}

function AccessChips(t, access, // Partial<UserPermissions>
group) {
  if (typeof access === 'undefined' || typeof group === 'undefined') return jsx("span", {});
  var ns = access.namespace(group);
  var nsKeys = PermissionMap[group].permissions;
  return nsKeys.map(function (key) {
    var hasPermission = ns.hasPermissions([key]); // if (typeof value === 'undefined') return <span />;

    return jsx(Chip, {
      color: hasPermission ? 'success' : 'error',
      title: t(key + "." + hasPermission),
      label: t(key + "." + hasPermission)
    }, key);
  });
}

var groups = ['organization', 'device', 'location', 'playlist', 'signage'];

function OrganizationCard(props) {
  var organizationUser = props.organizationUser,
      id = props.id,
      name = props.name,
      updatedAt = props.updatedAt,
      onFavorite = props.onFavorite,
      onClick = props.onClick;
  var isLg = useMediaQuery(function (theme) {
    return theme.breakpoints.up('lg');
  });

  var _useTranslation = useTranslation(['organization', 'common']),
      t = _useTranslation.t;

  var _useState = useState(false),
      processingFavorite = _useState[0],
      setProcessingFavorite = _useState[1]; // const isFavorite = user.defaultOrganizationId === id;


  var isFavorite = false;
  var favoriteIcon = favoriteStatus[isFavorite ? 'checked' : 'unchecked'];

  var onChangeFavorite = function onChangeFavorite(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (typeof onFavorite === 'function') {
      setProcessingFavorite(true); // only call change if selected is currently not the favorite.

      onFavorite(props).then(function (result) {
        setProcessingFavorite(false);
      });
    }
  };

  var onCardClicked = function onCardClicked(evt) {
    if (typeof onClick === 'function') {
      evt.preventDefault();
      onClick(props);
    }
  };

  var onBtnClicked = function onBtnClicked(evt) {
    evt.stopPropagation();

    if (typeof onClick === 'function') {
      evt.preventDefault();
      onClick(props);
    }
  };

  return jsx(UndecoratedLink, {
    to: "/o/" + props.id + "/home",
    onClick: onCardClicked,
    children: jsxs(Organization, {
      variant: "outlined",
      children: [jsxs(FavoriteContainer, {
        children: [processingFavorite && jsx(PendingIcon, {}), jsx(IconButton, {
          onClick: onChangeFavorite,
          size: "large",
          children: jsx(FontAwesomeIcon, {
            icon: favoriteIcon
          })
        })]
      }), jsxs(CardContent, {
        sx: {
          paddingBottom: 0
        },
        children: [jsx(Typography, {
          variant: 'h3',
          gutterBottom: true,
          children: name
        }), jsxs(Stack, {
          gap: 1,
          children: [jsx(Typography, {
            color: 'textSecondary',
            variant: 'h4',
            fontSize: '1.4em',
            noWrap: true,
            children: t('portal.content.item.access')
          }), jsx(Grid, {
            container: true,
            spacing: 1,
            children: groups.map(function (group) {
              return jsx(Grid, {
                item: true,
                xs: 12,
                sm: 'auto',
                children: jsxs(Paper, {
                  component: Stack,
                  p: 1,
                  width: '100%',
                  height: '100%',
                  children: [jsxs(Typography, {
                    gutterBottom: true,
                    sx: {
                      textTransform: 'capitalize'
                    },
                    children: [group, " Level:"]
                  }), jsx(Stack, {
                    direction: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                    alignItems: 'stretch',
                    gap: 1,
                    flexGrow: 1,
                    children: jsx(AccessIcons, {
                      t: t,
                      size: '1x',
                      // size={isLg ? '2x' : '1x'}
                      access: organizationUser.permissions,
                      group: group
                    })
                  })]
                })
              }, group);
            })
          })]
        }), jsx(Box, {
          marginTop: 1,
          children: jsx(Typography, {
            variant: 'subtitle2',
            children: t('portal.content.item.lastModified', {
              date: DateTime.fromISO(updatedAt).toFormat(FULL_DATE_FORMAT)
            })
          })
        })]
      }), jsx(CardActions, {
        sx: {
          ml: 1,
          mb: 1
        },
        children: jsx(Button, {
          onClick: onBtnClicked,
          variant: 'contained',
          focusRipple: true,
          color: 'primary',
          size: "small",
          children: t('portal.content.item.actions.select')
        })
      })]
    })
  });
}

export { OrganizationCard };
