import { useState, useCallback } from 'react';

function useStateStorage(props) {
  var _ref = props || {},
      type = _ref.type,
      namespace = _ref.namespace,
      defaultValue = _ref.defaultValue,
      key = _ref.key,
      shouldStore = _ref.shouldStore;

  var _useState = useState(window.store[type || 'session'].namespace(namespace || 'default').get(key, defaultValue)),
      state = _useState[0],
      setState = _useState[1];

  var _useState2 = useState(typeof window.store[type || 'session'].namespace(namespace || 'default').get(key) !== 'undefined'),
      stored = _useState2[0],
      setStored = _useState2[1];

  var setter = useCallback(function (value, condition) {
    if (condition === void 0) {
      condition = typeof shouldStore === 'boolean' ? shouldStore : true;
    }

    var storedValue = value;

    if (typeof value === 'function') {
      storedValue = value(state);
    }

    setState(value);

    if (condition) {
      if (typeof storedValue === 'undefined') {
        setStored(false);
        window.store[type || 'session'].namespace(namespace || 'default').remove(key);
      } else {
        setStored(true);
        window.store[type || 'session'].namespace(namespace || 'default').set(key, storedValue);
      }
    }
  }, [state, shouldStore]);
  return [state, setter, stored];
}

export { useStateStorage };
