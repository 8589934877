import { useLocation } from 'react-router-dom';
import '../util/index.esm.js';
import { parseQuerystring } from '../util/functions.esm.js';

function useQuery() {
  var location = useLocation();
  return parseQuerystring(location.search);
}

export { useQuery };
