import { useState, useRef, useMemo, useCallback, useEffect } from 'react';

function usePromise(promise, props) {
  if (props === void 0) {
    props = {};
  }

  var _props = props,
      resolve = _props.resolve,
      args = _props.args;

  var _useState = useState(null),
      data = _useState[0],
      setData = _useState[1];

  var _useState2 = useState(null),
      error = _useState2[0],
      setError = _useState2[1];

  var _useState3 = useState(false),
      loading = _useState3[0],
      setLoading = _useState3[1];

  var lastUpdate = useRef(null);
  var resolved = useMemo(function () {
    return data !== null;
  }, [data]);
  var execute = useCallback(function () {
    for (var _len = arguments.length, providedArgs = new Array(_len), _key = 0; _key < _len; _key++) {
      providedArgs[_key] = arguments[_key];
    }

    if (args === null && providedArgs.length === 0) {
      return Promise.resolve(undefined);
    }

    setError(null);
    setData(null);
    setLoading(true);
    return promise.apply(void 0, providedArgs.length > 0 ? providedArgs : args || []) // return promise(...[...(args || []), ...(providedArgs || [])])
    .then(function (response) {
      setData(response);
      setError(null);
      lastUpdate.current = Date.now();
      return response;
    }).catch(function (err) {
      setError(err);
      return err;
    }).finally(function () {
      setLoading(false);
    });
  }, [promise, args]);
  var reset = useCallback(function () {
    setData(null);
    setError(null);
  }, []);
  useEffect(reset, [args]);
  useEffect(function () {
    if (!(resolve && !resolved)) {
      return;
    }

    execute();
  }, [resolve, resolved]);
  return {
    resolved: resolved,
    execute: execute,
    lastUpdate: lastUpdate.current,
    data: data,
    error: error,
    loading: loading,
    reset: reset
  };
}

export { usePromise };
