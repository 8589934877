import { jsx, jsxs } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { Backdrop as Backdrop$1, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

var Backdrop = styled(Backdrop$1)(function (_ref) {
  var theme = _ref.theme;
  return {
    position: 'absolute'
  };
});

function LoadingFallback(props) {
  var backdrop = useMemo(function () {
    return jsx(Backdrop, {
      open: props.loading,
      sx: {
        zIndex: 1000
      },
      children: jsx("img", {
        src: '/loading.svg',
        width: 75
      })
    });
  }, [props.loading]);
  if (!props.children) return backdrop;
  console.log({
    children: props.children,
    backdrop: backdrop
  });
  return jsxs(Box, {
    position: 'relative',
    children: [backdrop, props.children]
  });
}

LoadingFallback.defaultProps = {
  loading: true
};

export { LoadingFallback };
