import { jsx, jsxs } from 'react/jsx-runtime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCirclePlus, faPenCircle, faTrash, faMonitorWaveform, faPlayPause, faUser, faUserSlash, faUsersGear, faCode, faSquare } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus as faCirclePlus$1, faPenCircle as faPenCircle$1, faTrash as faTrash$1, faMonitorWaveform as faMonitorWaveform$1, faUsersGear as faUsersGear$1, faNetworkWired } from '@fortawesome/pro-regular-svg-icons';
import { styled } from '@mui/material';
import clsx from 'clsx';

var ThemedIcon = styled(FontAwesomeIcon)(function (_ref) {
  var _theme$palette$color;

  var theme = _ref.theme,
      color = _ref.color;
  return {
    color: color && theme.palette[color] ? ((_theme$palette$color = theme.palette[color]) == null ? void 0 : _theme$palette$color.main) || theme.palette.text.primary : color || theme.palette.text.primary
  };
});
var Span = styled('span')(function () {
  return {};
});
var permissionIcons = {
  READ: function READ(_ref2) {
    var enabled = _ref2.enabled,
        size = _ref2.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: enabled ? faEye : faEyeSlash
    });
  },
  CREATE: function CREATE(_ref3) {
    var group = _ref3.group,
        enabled = _ref3.enabled,
        size = _ref3.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: enabled ? faCirclePlus : faCirclePlus$1
    });
  },
  UPDATE: function UPDATE(_ref4) {
    var enabled = _ref4.enabled,
        size = _ref4.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: enabled ? faPenCircle : faPenCircle$1
    });
  },
  DELETE: function DELETE(_ref5) {
    var enabled = _ref5.enabled,
        size = _ref5.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: enabled ? faTrash : faTrash$1
    });
  },
  WATCH: function WATCH(_ref6) {
    var enabled = _ref6.enabled,
        size = _ref6.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: enabled ? faMonitorWaveform : faMonitorWaveform$1
    });
  },
  UPDATE_STATUS: function UPDATE_STATUS(_ref7) {
    var enabled = _ref7.enabled,
        size = _ref7.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: faPlayPause
    });
  },
  ACTIVE: function ACTIVE(_ref8) {
    var enabled = _ref8.enabled,
        size = _ref8.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: enabled ? faUser : faUserSlash
    });
  },
  ADMIN: function ADMIN(_ref9) {
    var enabled = _ref9.enabled,
        size = _ref9.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: enabled ? faUsersGear : faUsersGear$1
    });
  },
  ACCESS_TOKENS: function ACCESS_TOKENS(_ref10) {
    var enabled = _ref10.enabled,
        size = _ref10.size;
    return jsx(ThemedIcon, {
      fixedWidth: true,
      size: size,
      color: enabled ? 'success' : 'error',
      icon: faCode
    });
  },
  INTEGRATION_DELETE: function INTEGRATION_DELETE(_ref11) {
    var enabled = _ref11.enabled,
        size = _ref11.size;
    return jsxs(Span, {
      className: clsx('fa-stack fa-fw', size ? {
        'fa-sm': size === '1x' || typeof size === 'undefined',
        'fa-1x': size === '2x',
        'fa-2x': size === '3x'
      } : {}),
      children: [jsx(ThemedIcon, {
        icon: faTrash,
        className: 'fa-stack-2x',
        color: enabled ? 'success' : 'error'
      }), jsx(ThemedIcon, {
        icon: faNetworkWired,
        color: 'white',
        className: 'fa-stack-1x',
        transform: {
          y: 2
        }
      })]
    });
  },
  INTEGRATION_CREATE: function INTEGRATION_CREATE(_ref12) {
    var enabled = _ref12.enabled,
        size = _ref12.size;
    return jsxs(Span, {
      className: clsx('fa-stack fa-fw', size ? {
        'fa-sm': size === '1x' || typeof size === 'undefined',
        'fa-1x': size === '2x',
        'fa-2x': size === '3x'
      } : {}),
      children: [jsx(ThemedIcon, {
        color: enabled ? 'success' : 'error',
        icon: faSquare,
        className: 'fa-stack-2x'
      }), jsx(ThemedIcon, {
        icon: faNetworkWired,
        color: 'white',
        className: 'fa-stack-1x'
      })]
    });
  },
  INTEGRATION_READ: function INTEGRATION_READ(_ref13) {
    var enabled = _ref13.enabled,
        size = _ref13.size;
    return jsxs(Span, {
      className: clsx('fa-stack fa-fw', size ? {
        'fa-sm': size === '1x' || typeof size === 'undefined',
        'fa-1x': size === '2x',
        'fa-2x': size === '3x'
      } : {}),
      children: [jsx(ThemedIcon, {
        color: enabled ? 'success' : 'error',
        icon: faSquare,
        className: 'fa-stack-2x'
      }), jsx(ThemedIcon, {
        icon: faNetworkWired,
        className: 'fa-stack-1x',
        color: 'white',
        style: {
          height: '.75em',
          width: '1em'
        },
        transform: {
          y: 8,
          x: 0
        }
      }), jsx(ThemedIcon, {
        icon: faEye,
        className: 'fa-stack-1x',
        color: 'white',
        style: {
          height: '.75em',
          width: '1em'
        },
        transform: {
          y: -9
        }
      })]
    });
  }
};

export { permissionIcons };
