import { useCallback, useEffect } from 'react';

var globalCache = {};
var globalAttached = false;

function useKeyboard(id, callback, props) {
  var globalListener = useCallback(function (evt) {
    Object.values(globalCache).forEach(function (fn) {
      if (evt.defaultPrevented) return;
      fn(evt);
    });
  }, []);
  useEffect(function () {
    if (id && callback) {
      globalCache[id] = callback;
    }

    if (!globalAttached && Object.keys(globalCache).length > 0) {
      globalAttached = true;
      document.addEventListener('keydown', globalListener);
    }

    return function () {
      delete globalCache[id];

      if (Object.keys(globalCache).length === 0) {
        globalAttached = false;
        document.removeEventListener('keydown', globalListener);
      }
    };
  }, [id, callback, globalListener]);
}

export { useKeyboard };
