import { DateTime } from 'luxon';

function apiFetch(input, init) {
  if (process.env.NODE_ENV === 'development') {
    return new Promise(function (resolve) {
      setTimeout(resolve, 1500);
    }).then(function () {
      return fetch(input, init);
    });
  }

  return fetch(input, init);
}
function dummyEvt(name) {
  return {
    currentTarget: {
      name: name
    },
    target: {
      name: name
    }
  };
}
function millisToReadable(ms) {
  var seconds = ms / 1000; // .toFixed(1);

  var minutes = ms / (1000 * 60); // .toFixed(1);

  var hours = ms / (1000 * 60 * 60); // .toFixed(1);

  var days = ms / (1000 * 60 * 60 * 24); // .toFixed(1);

  if (seconds < 60) return seconds.toFixed(1) + " Sec";
  if (minutes < 60) return minutes.toFixed(1) + " Min";
  if (hours < 24) return hours.toFixed(1) + " Hrs";
  return days + " Days";
}
function millisToDuration(millis) {
  var millStr = Math.floor(millis / 1000).toString();
  var secs = Number.parseInt(millStr, 10);
  var mins = 0;
  var hours = 0;

  while (secs >= 60) {
    // eslint-disable-next-line
    mins++;
    secs -= 60;
  }

  while (mins >= 60) {
    // eslint-disable-next-line
    hours++;
    mins -= 60;
  }

  return ("" + hours).padStart(2, '0') + ":" + ("" + mins).padStart(2, '0') + ":" + ("" + secs).padStart(2, '0');
}
function millisToDisplay(millis) {
  if (typeof millis !== 'number') return millis;
  var seconds = millis / 1000;
  var minutes = seconds / 60;
  var hours = minutes / 60;
  var days = hours / 24;
  if (days >= 1) return days.toPrecision(3) + " day" + (days >= 2 ? 's' : '');
  if (hours >= 1) return hours.toPrecision(3) + " hour" + (hours >= 2 ? 's' : '');
  if (minutes >= 1) return minutes.toPrecision(3) + " minute" + (minutes >= 2 ? 's' : '');
  if (seconds >= 1) return seconds.toPrecision(3) + " second" + (seconds >= 2 ? 's' : '');
  return millis.toPrecision(3) + " millisecond" + (millis >= 2 ? 's' : '');
}
function durationToMillis(value) {
  var _value$split$map$map = value.split(':').map(function (k) {
    return k.substr(0, k.indexOf(' ') >= 0 ? k.indexOf(' ') : k.length);
  }).map(function (i) {
    return Number.parseInt(i, 10) || 0;
  }),
      hrs = _value$split$map$map[0],
      mins = _value$split$map$map[1],
      secs = _value$split$map$map[2]; // .map(i => Number.parseInt(i.trim()))


  return secs * 1000 + mins * 60 * 1000 + hrs * 60 * 60 * 1000;
}
function isItemActive(item) {
  var endDateDiff = (typeof item.endDate === 'object' && item.endDate ? item.endDate : DateTime.fromISO(item.endDate || '')).diffNow('milliseconds').milliseconds || 0;
  var startDateDiff = (typeof item.startDate === 'object' && item.startDate ? item.startDate : DateTime.fromISO(item.startDate || '')).diffNow('milliseconds').milliseconds || 0;
  var isBeforeStartDate = startDateDiff > 0;
  var isPastEndDate = endDateDiff < 0;
  return !isPastEndDate && !isBeforeStartDate;
}
function isDOMChild(parentObj, obj) {
  while (obj !== undefined && obj !== null && ((_obj$tagName = obj.tagName) == null ? void 0 : _obj$tagName.toUpperCase()) !== 'BODY') {
    var _obj$tagName;

    if (obj === parentObj) {
      return true;
    } // eslint-disable-line


    obj = obj.parentNode;
  }

  return false;
}
function hasOrganizationAccess(item, organization) {
  var _item$access$integrat;

  if (typeof item === 'undefined') return true;
  if (typeof organization === 'undefined') return false;
  var orgUserPerms = organization.organizationUser.permissions;
  var orgPerms = orgUserPerms.namespace('organization'); // is admin, has full access

  var isAdmin = orgPerms.hasPermissions(['ADMIN']); // is inactive/banned/disabled, no access

  if (!isAdmin && !orgPerms.hasPermissions(['ACTIVE'])) return false; // no access settings are set, default to accessible

  if (typeof item.access === 'undefined' || Object.keys(item.access.permissions || {}).length === 0 && Object.keys(((_item$access$integrat = item.access.integrations) == null ? void 0 : _item$access$integrat.use) || {}).length === 0) return true;
  var accessible;

  if (!isAdmin && item.access.permissions) {
    var accessPerms = item.access.permissions;
    accessible = orgUserPerms.compare(accessPerms);
  } else {
    accessible = true;
  }

  if (accessible && item.access.integrations && organization.integrations && Object.keys(organization.integrations).length > 0) {
    var mode = item.access.integrations.mode || 'AND';
    var containedIntegrations = item.access.integrations.use.filter(function (integration) {
      if (!organization.integrations) {
        return false;
      }

      return Boolean(organization.integrations[integration]);
    });
    if (mode === 'AND') accessible = containedIntegrations.length === item.access.integrations.use.length;else accessible = containedIntegrations.length > 0; // console.log(item.nameKey, accessible, containedIntegrations, organization.integrations, item.access.integrations)
  } else if (item.access.integrations && Object.keys(item.access.integrations).length > 0) {
    accessible = false;
  }

  return accessible;
}
function capitalize(str) {
  if (typeof str !== 'string' || str.length === 0) return str;
  return str.split(' ').map(function (word) {
    return word.charAt(0).toUpperCase() + word.substr(1);
  }).join(' ');
}
function parseQuerystring(search) {
  var query = {};

  if (search.charAt(0) === '?') {
    search.substr(1).split('&').map(function (kvp) {
      var parts = kvp.split('=');
      query[parts[0]] = typeof parts[1] === 'undefined' ? true : decodeURIComponent(parts[1]);
    });
  }

  return query;
} // TODO move to types

var datePattern = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])/;
function parseDates(data) {
  if (data) {
    var wasSingle = false;

    if (!(data instanceof Array)) {
      // eslint-disable-line
      data = [data];
      wasSingle = true;
    } // eslint-disable-line


    data = data.map(function (obj) {
      Object.keys(obj).forEach(function (key) {
        var value = obj[key];

        if (value instanceof Array || typeof value === 'object') {
          value = parseDates(value);
        }

        if (typeof value === 'string') {
          if (value.match(datePattern)) {
            // eslint-disable-line
            obj[key] = DateTime.fromISO(value);
          }
        }
      });
      return obj;
    });
    if (wasSingle) return data[0];
    return data;
  }

  return null;
}

export { apiFetch, capitalize, dummyEvt, durationToMillis, hasOrganizationAccess, isDOMChild, isItemActive, millisToDisplay, millisToDuration, millisToReadable, parseDates, parseQuerystring };
